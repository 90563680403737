import { Box, Grid, Typography } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { useCookies } from 'react-cookie';

import { useOktaAuth } from '@okta/okta-react';

import AppButton from '../components/AppButton';
import { MirAppBarContext, miRAppBarType } from '../providers/MirAppBarProvider';
import shipping_logo from './../shipping_logo.svg';

const Protected = () => {
  const { updateMirAppbar } = useContext(MirAppBarContext) as miRAppBarType;
  const { oktaAuth } = useOktaAuth();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(['mir_auth']);

  useEffect(() => {
    oktaAuth.getUser().then((info) => {
      updateMirAppbar({
        logout: _handleLogout,
        user: info
      })
    }).catch((err) => {
      console.error(err);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _handleLogout = () => {
    removeCookie('mir_auth', { domain: process.env.REACT_APP_COOKIES_DOMAIN })
    oktaAuth.signOut({ postLogoutRedirectUri: process.env.REACT_APP_MIR_LOGIN_APP })
  }

  const _handleClick = () => {
    if (!!process.env.REACT_APP_SHIPPING_APP) {
      const a = document.createElement('a');
      a.href = process.env.REACT_APP_SHIPPING_APP
      a.click();
    }
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 70px)' }}>

      <Grid container
        style={{ width: '100%', marginTop: '20px' }}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Typography variant='h4'>You're logged in!</Typography>
        </Grid>

        <Grid item xs={12} />

        <Grid item>
          <Typography variant='h5'>You can now choose where to go</Typography>
        </Grid>
      </Grid>

      <Grid container
        style={{ width: '100%' }}
        justifyContent="center"
        alignItems="center"
        sx={{
          flex: 0.75
        }}
      >
        <Grid item>
          <AppButton title='Shipping app' image={shipping_logo} onClick={_handleClick} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Protected;