const CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID || '{clientId}';
const ISSUER = process.env.REACT_APP_OKTA_ISSUER || 'https://{yourOktaDomain}.com/oauth2/default';
const OKTA_TESTING_DISABLEHTTPSCHECK = process.env.REACT_APP_OKTA_OKTA_TESTING_DISABLEHTTPSCHECK || false;
const BASENAME = process.env.REACT_APP_OKTA_PUBLIC_URL || '';
const REDIRECT_URI = `${window.location.origin}${BASENAME}/login/callback`;
const USE_INTERACTION_CODE = process.env.REACT_APP_OKTA_USE_INTERACTION_CODE === 'true' || false;

const defaultConfig =  {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: REDIRECT_URI,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
    useInteractionCode: USE_INTERACTION_CODE,
  },
  storageManager: {
    token: {
      storageTypes: ['cookie']
    }
  },
  tokenManager: {
    storage: 'cookie'
  },
  app: {
    basename: BASENAME,
  },
};

export default defaultConfig;
