import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';

import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { deepmerge } from '@mui/utils';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import mirSentinelTheme from './mir-styles/src/styles/general';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const mirSentinelLocal = createTheme({
  typography: {
    h4: {
      color: "#ff6565"
    },
    h5: {
      color: mirSentinelTheme.palette.primary.dark
    }
  }
});

export const mergedTheme = createTheme(deepmerge(mirSentinelTheme, mirSentinelLocal))

root.render(
  <React.StrictMode>
    <ThemeProvider theme={mergedTheme}>
      <CssBaseline/>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
