import React, { useEffect, useState } from 'react';

import { UserClaims } from '@okta/okta-auth-js';

import { useMediaQuery } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';

import mirLogo from './../../assets/mir_logo.jpg'
import mirSentinelTheme from '../../styles/general';

interface miRAppBarProps {
  user?: UserClaims,
  logout?: () => void,
  login?: () => void;
  imageSrc?: string;
  title?: string;
}


const MirAppBar: React.FC<miRAppBarProps> = ({ user, logout, login, imageSrc, title }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const smallScreen = useMediaQuery(mirSentinelTheme.breakpoints.down('sm'));

  useEffect(() => {
    if (!smallScreen) setAnchorEl(null)
  }, [smallScreen]);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (smallScreen) setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="static" color='transparent'>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <img src={!!imageSrc ? imageSrc : mirLogo} style={{ height: '45px', marginTop: '10px' }} className="App-logo" alt="logo" />
          <Typography
            variant="h5"
            noWrap
            component="a"
            sx={{
              paddingTop: '5px',
              marginLeft: '30px',
              display: { xs: 'none', md: 'flex' },
              fontWeight: 700,
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            {!!title ? title : ''}
          </Typography>

          <Box sx={{ flexGrow: 1 }} />

          <Box sx={{ flexGrow: 0 }}>
            {!!user
              ?
              <>
                <Tooltip title={user?.name || 'User'}>
                  <IconButton sx={{ p: 0 }} onClick={handleMenu}>
                    <Avatar
                      alt={user?.name}
                      sx={{ bgcolor: mirSentinelTheme.palette.primary.main }}
                    >
                      {user?.name?.match(/\b\w/g)?.join('')}
                    </Avatar>
                  </IconButton>
                </Tooltip>
                {
                  smallScreen
                    ?
                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem onClick={handleClose}>My account</MenuItem>
                    </Menu>
                    : null
                }
              </>
              : null}
            {!!logout && !smallScreen
              ?
              <>
                &nbsp;
                &nbsp;
                &nbsp;
                <Button variant="text" onClick={logout}>
                  Logout
                </Button>
              </>
              : null
            }

          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default MirAppBar;
