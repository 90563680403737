import React, { useContext } from 'react';
import { Routes, Route } from 'react-router-dom';

import { LoginCallback } from '@okta/okta-react';

import Loading from './Loading';
import { RequiredAuth } from './SecureRoute';
import Login from '../pages/Login/Login';
import Protected from '../pages/Protected';
import MirAppBar from '../mir-styles/src/components/AppBar/MirAppBar';
import { MirAppBarContext, miRAppBarType } from '../providers/MirAppBarProvider';

const AppRoutes = () => {
  const { mirAppBarState } = useContext(MirAppBarContext) as miRAppBarType;

  return (
    <>
    <MirAppBar title={mirAppBarState.title} logout={mirAppBarState.logout} user={mirAppBarState.user}/>
    <Routes>
      <Route path='/' element={<Login />} />
      <Route path='/login/callback' element={<LoginCallback loadingElement={<Loading />} />} />
      <Route path='/protected' element={<RequiredAuth />}>
        <Route path='' element={<Protected />} />
      </Route>
    </Routes>
    </>
  );
};

export default AppRoutes;